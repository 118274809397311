import React from "react";
import Page, { SECTION, ROW, HEADING, TAGLINE, TEXT, CONTENT } from '../components/Page';

import { MailChimpSignup } from '../components/MailChimp';

const StrategicSoftware = () => {

  return (
    <Page>
      <SECTION>
        <HEADING>Tech Insights for Ambitious Professionals</HEADING>

        <TAGLINE>Join <TEXT>500+</TEXT> entrepreneurs, software engineers and tech enthusiasts building products and leading teams.</TAGLINE>
      </SECTION>

      <SECTION>
        <MailChimpSignup />

        <CONTENT>
          <TEXT><em>Strategic Software</em></TEXT> features actionable insights and curated news across business, technology and leadership.
        </CONTENT>

        <CONTENT>
          Delivered free to your inbox ✉️ monthly(ish).
        </CONTENT>
      </SECTION>

      <SECTION>
        <div className="senja-frame-embed" data-id="7b8eadb5-7b8d-4031-a986-620665aaa144"></div>
        <script async type="text/javascript" src="https://widget.senja.io/embed/frame.js"></script>
      </SECTION>
    </Page>
  );
};

export default StrategicSoftware;
