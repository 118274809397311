import React from "react";

export const MailChimpSignup = () => (
    <>
      {/* Begin Mailchimp Signup Form */ }
      <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
  
      <div
        id="mc_embed_signup" 
        style={{ 
          color: '#fff',
          clear: 'left', 
          fontSize: '14px'
        }}
      >
        <form
          action="https://securewebsolutions.us1.list-manage.com/subscribe/post?u=146b64a4b90a55f1a937221f2&amp;id=f467fdb108&amp;f_id=009273e2f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'stretch' }}>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
                style={{ flex: 2 }} 
              />
  
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                style={{ width: 400, backgroundColor: '#40b1e8', flex: 1, margin: '0px 4px', height: 'auto' }} 
              />
            </div>
  
            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none'}}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none'}}></div>
            </div>    {/*real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
  
            <div style={{ position: 'absolute', left: '-5000px'}} aria-hidden="true">
              <input type="text" name="b_146b64a4b90a55f1a937221f2_f467fdb108" tabIndex={-1} />
            </div>
          </div>
        </form>
      </div>
    </>
  );